export default [
  {
    header: 'Dashboard',
  },
  {
    title: 'Usage Statistics',
    route: 'home',
    icon: 'PieChartIcon',
  },
]
