export default [
  {
    header: 'API Settings',
  },
  {
    icon: 'KeyIcon',
    title: 'API Keys',
    route: 'api-keys',
  },
]
