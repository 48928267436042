export default [
  {
    header: 'Address Books',
  },
  {
    title: 'Contact Groups',
    route: 'address-books-groups',
    icon: 'BookIcon',
  },
]
